import React from "react"
import { Link } from "react-router-dom"
import "./header.scss"
import Component from "../common/component"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'


export default class Header extends Component {

	render() {
		return (
			<nav className='navbar navbar-expand-lg'>
				<div className="container">
					<Link className='navbar-brand' to={"/"}>
						Таро на завтра
					</Link>
					{/* <nav className='nav pb'>
						<Link className='nav-link' activeClassName='active' to={`/`} exact={true}>Тендеры</Link>
						<Link className='nav-link' activeClassName='active' to={`/queries`}>Запросы</Link>
						<Link className='nav-link' activeClassName='active' to={`/markets`}>Площадки</Link>
					</nav>
					<a className='' href="https://t.me/+ZNzfZCVdKiwzMDhi" target="_blank">
						<img src={"/telegram.png"} width={25}/>
					</a> */}
					<nav className="nav pb">
						<Link className="nav-link" activeClassName='active' to={'/settings'} title="Настройки">
							<FontAwesomeIcon icon={faGear} />
						</Link>
					</nav>
					<button
						className='navbar-toggler'
						type='button'
						data-toggle='collapse'
						data-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>
				</div>
			</nav>
		)
	}
} 
