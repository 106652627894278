import React from "react"
import "./three_cards.scss"
import Component from "./common/component"
import Button from "react-bootstrap/esm/Button"
import ReactSelect from 'react-select'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cookies from "universal-cookie"

export default class Settings extends Component {

	state = {
		skins: null,
		skin: null,
		skinAlias: null,
	}

	componentDidMount() {
		const cookies = new Cookies(null, { path: '/' });

		super.componentDidMount()
		this.api().skins().then(result => {
			console.log(result)
			let skins = result.map(x => { return { label: x.name, value: x.alias }})
			let skinAlias = cookies.get("deck_skin_alias")
			if(!skinAlias) {
				skinAlias = skins[0].alias
				cookies.set("deck_skin_alias", skinAlias)
			}
			this.setState({
				skins,
				skinAlias,
				skin: skins.find(x => x.value === skinAlias),
			})
			console.log("SkinAlias", skinAlias)
		})
	}

	componentDidUpdate(prevProps, prevState) {
	}

	render() {
		const { skins, skin } = this.state

		const handleOnSkinChange = (e) => {
			const cookies = new Cookies(null, { path: '/' })

			console.log(e)
			this.setState({
				skinAlias: e.value,
				skin: e,
			})
			cookies.set("deck_skin_alias", e.value)
			
		} 

		return <>
			<Container>
				<Row>
					<Col>
						<Form>
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label>Отображение карт</Form.Label>
								<ReactSelect
									isSearchable={false} 
									className="react-select"
									value={skin}
									onChange={handleOnSkinChange}
									placeholder="Выберите колоду"
									options={skins}/>					
							</Form.Group>
						</Form>
					</Col>
				</Row>
			</Container>
			<Button>Сохранить</Button>
		</>
	}
}
