import Http from "./http"

export default class API {
    constructor(context = null) {
        this.http = new Http(process.env.REACT_APP_API_URL, null)

        //console.log("API init with context:", context)
        if (context && context.api) {
            this.context = context
            this.csrf = context.api.csrf
            console.log(`csrf: ${this.csrf}`)
        }
    }

    skins() {
        return this.http.get("skins/")
    }

    cards() {
        return this.http.get("cards/")
    }

    card(id) {
        return this.http.get(`cards/${id}`)
    }

    threeCardsMeaning(params) {
        return this.http.get(`cards/three_cards_meaning`, params)
    }
    
    stats() {
        return this.http.get(`three_cards_meanings/stats`)
    }

    major() {
        return this.http.get(`cards/major`)
    }

    minor(suit) {
        return this.http.get(`cards/minor`, {suit})
    }
}