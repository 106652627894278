import './App.scss';
import React from 'react'
import PageNotFound from './components/pages/page_not_found'
import ThreeCards from './components/three_cards'
import Header from './components/layouts/header'
import Footer from './components/layouts/footer'
import {
  BrowserRouter,
  createBrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Settings from './components/settings';

function App() {

  const router = createBrowserRouter([
    { path: "/", element: <ThreeCards/>, errorElement: <PageNotFound/> },
  ])

  console.log("App component")

  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <div className='container main'>
          <Routes>
              <Route path="/" element={<ThreeCards/>}/>
              <Route path="/settings" element={<Settings/>}/>
          </Routes>
        </div>
        <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App;
