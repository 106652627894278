import React from "react"
import API from "../../classes/api"
import Cookies from "universal-cookie"

class Component extends React.PureComponent {
	_api = null
	_cookies = null
	mounted = false

	cookies() {
		if (!this._cookies) this._cookies = new Cookies()
		return this._cookies
	}

	api() {
		if (!this._api) this._api = new API(null, "component")
		return this._api
	}

	// Override it
	handleUpdate(e) {}

	componentDidMount() {
		this.mounted = true
		this.handleUpdate = this.handleUpdate.bind(this)
	}

	componentWillUnmount() {
		this.mounted = false
	}

	handleUpdateOn() {
		this.bus().on("ws", "receive", this.handleUpdate)
	}

	handleUpdateOff(å) {
		this.bus().off("ws", "receive", this.handleUpdate)
	}

	setStateSafe(state) {
		if (!this.mounted) return
		this.setState(state)
	}
}

export default Component
