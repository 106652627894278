import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AppContextProvider } from './classes/app_context'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/react-fontawesome'
import '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core'

const root = ReactDOM.createRoot(document.getElementById('root'))

console.log("Index.js")

root.render(
    <AppContextProvider>
      <App />
    </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
