import React from "react"
import "./three_cards.scss"
import Card from './card'
import Component from "./common/component"
import Button from "react-bootstrap/esm/Button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Spinner from 'react-bootstrap/Spinner'

export default class ThreeCards extends Component {
	state = {
		card1: null,
		card2: null,
		card3: null,
		rev1: false,
		rev2: false,
		rev3: false,
		isValid: false,
		invalid: [],
		meaning: null,
		isLoading: false,
	}

	componentDidMount() {
		super.componentDidMount()
		console.log("Three cards component did mounted")
	}

	componentDidUpdate(prevProps, prevState) {
		const { card1, card2, card3, rev1, rev2, rev3 } = this.state
		if( prevState.card1 !== card1 || prevState.card2 !== card2 || prevState.card3 !== card3 || 
			prevState.rev1 !== rev1 || prevState.rev2 !== rev2 || prevState.rev3 !== rev3) {
			const cardSet = [...new Set([card1, card2, card3])]
			let valid = true
			if (cardSet.length < 3) {
				valid = false
				if(card1 !== null && card1 === card2) this.setState({invalid: [1, 2]})
				if(card1 !== null && card1 === card3) this.setState({invalid: [1, 3]})
				if(card2 !== null && card2 === card3) this.setState({invalid: [2, 3]})
				if(card1 !== null && card2 === card3 && card1 === card3) this.setState({invalid: [1, 2, 3]})
			} else {
				this.setState({invalid: []})
			}
			if (card1 === null || card2 === null || card3 === null) valid = false
			this.setState({isValid: valid, meaning: null})
		}
	}

	render() {
		const { card1, card2, card3, rev1, rev2, rev3, isValid, meaning, isLoading, invalid } = this.state

		const handleMeaning = () => {
			this.setState({isLoading: true})
			this.api().threeCardsMeaning({card1, rev1, card2, rev2, card3, rev3}).then(result => {
				if(result) this.setState({meaning: result.text, isLoading: false})
			})
		}

		const handleCloseMeaning = () => {
			this.setState({meaning: null})
		}
	
		return <div className="three-cards">
			<div className="row" style={{marginBottom: 20}}>
				<div className="col">
					<Card id="card1" onChange={(card, reverse) => this.setState({card1: card, rev1: reverse})} invalid={invalid.find(x => x === 1)}/>
				</div>
				<div className="col">
					<Card id="card2" onChange={(card, reverse) => this.setState({card2: card, rev2: reverse})} invalid={invalid.find(x => x === 2)}/>
				</div>
				<div className="col">
					<Card id="card3" onChange={(card, reverse) => this.setState({card3: card, rev3: reverse})} invalid={invalid.find(x => x === 3)}/>
				</div>
			</div>
			{isValid ?
				<div className="self-meaning">
					<div><label htmlFor="self-meaning">Попробуйте составить свое толкование:</label></div>
					<div><textarea id="self-meaning" rows={5}></textarea></div>
				</div>
			: null }
			{!isLoading && meaning===null ? 
				<div className="row">
					<Button disabled={!isValid} onClick={handleMeaning}>Показать толкование</Button>
				</div>
			: null }
			{isLoading ? <Spinner/> : null}
			{meaning !== null ?
				<div className="meaning">
						<div className="close"><FontAwesomeIcon onClick={handleCloseMeaning} icon={faXmark} style={{cursor: 'pointer'}} /></div>
						{meaning}
				</div> 
			: null }
		</div>
	}
}
