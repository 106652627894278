import React from "react"
import './footer.scss'
import Component from "../common/component"
import Utils from '../../classes/utils'

export default class Footer extends Component {
	state = {
		stats: null
	}

	componentDidMount() {
		super.componentDidMount()

		this.api().stats().then(stats => this.setState({stats}))
	}

	render() {
		const { stats } = this.state

		return <footer style={{ textAlign: "center" }}>
			<div className="container footer">
				{/* {stats ? <div>Толкований в базе: {Utils.digits(stats.db_count)} из {Utils.digits(stats.total)} ({stats.percents}%)</div> : null } */}
				<div>© <a href="https://t.me/greywolf_harmonica" target="blank">Grey Wolf</a> & <a href="https://www.instagram.com/daphnegracilis?igsh=ZGNjOWZkYTE3MQ%3D%3D&utm_source=qr" target="blank">Sophie Mower</a>, 2023</div>
			</div>
		</footer>		
	}
}