import React from "react"
import "./card.scss"
import Component from "./common/component"
import Spinner from 'react-bootstrap/Spinner'
import ReactSelect from "react-select"
import Switch from 'react-bootstrap/Switch'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Cookies from "universal-cookie"

export default class Card extends Component {
	card_type_options = [
		{label: "Старшие арканы", value: 0},
		{label: "Мечи", value: 1},
		{label: "Жезлы", value: 2},
		{label: "Кубки", value: 3},
		{label: "Пентакли", value: 4},
	]

	state = {
		card_type: this.card_type_options[0],
		cards: null,
		card: null,
		code: null,
		meaning: null,
		reversed: false,
		isLoading: false,
	}

	componentDidMount() {
		super.componentDidMount()
		this.fetch()
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.card_type.value !== this.state.card_type.value) {
			console.log("card type update")
			if (this.state.card_type.value === 0) 
				this.api().major().then(cards=>this.setState({cards, card: null, meaning: null, code: null}))
			else
				this.api().minor(this.state.card_type.value).then(cards=>this.setState({cards, card: null, meaning: null, code: null}))
		}
	}

	fetch() {
		this.api().major().then(cards => {
			this.setState({cards})
		})
	}

	render() {
		const { cards, card, code, reversed, meaning, isLoading, card_type } = this.state
		const { invalid } = this.props

		const cookies = new Cookies(null, "/")

		let skinAlias = cookies.get("deck_skin_alias")
		if(!skinAlias) skinAlias = "rider-waite"

		const handleCardChange = (e) => {
			console.log('handleChange', e)
			this.setState({card: e, meaning: null})
			this.api().card(e.value).then(card => {
				console.log(card)
				this.setState({
					code: card.code
				})
			})
			this.props.onChange(e.value, reversed)
		}

		const handleCardTypeChange = (type) => {
			console.log('handleCardTypeChange', type)
			this.setState({card_type: type})
			this.props.onChange(null, reversed)
		}

		const handleReverse = (e) => {
			const val = !this.state.reversed
			this.setState({reversed: val, meaning: null})			
			this.props.onChange(card.value, val)
		}

		const handleMeaning = () => {
			this.api().card(card.value).then(card => {
				this.setState({meaning: reversed ? card.meaning_reversed : card.meaning })
			})
		}

		const handleCloseMeaning = () => {
			this.setState({meaning: null})
		}

		if(!cards) return <div><Spinner/></div>
		return <div className="tarot-card">
			<div>
				<ReactSelect
						isSearchable={false} 
						className="react-select"
						options={this.card_type_options}
						value={card_type}
						placeholder={"Выберите тип карты"}
						onChange={handleCardTypeChange}
					/>
			</div>
			<div style={{marginTop:10}}>
				<ReactSelect
					isSearchable={false} 
					className="react-select"
					options={cards.map(c => { return { label: c.arcane ? c.name : `${c.number} ${c.name}`, value: c.id }})}
					value={card}
					placeholder={"Выберите карту"}
					onChange={handleCardChange}
				/>
			</div>
			<div style={{marginTop: 10}}>
				<Switch disabled={!card} value={reversed} onChange={handleReverse} label="Перевернутая" id={this.props.id} inline/>
			</div>
			<div className="card-placeholder">
				<div className={`shadow` + (invalid ? " invalid" : "")}>
					{code !== null ? 
						<img src={`/cards/${skinAlias}/${code}.png`} className={"card " + (reversed ? " reversed" : "")}/> : <img src={`/cards/${skinAlias}/back.png`} className="card"/> }
				</div>
			</div>
			{code !== null && meaning === null ?
				<div style={{margin: '10px 0px 30px 0px'}}>
					<Button onClick={handleMeaning}>Значение</Button>
				</div>
			: null }
			{meaning !== null ? 
				<div className="meaning">
					<div dangerouslySetInnerHTML={{__html: meaning}}></div>
					<div className="close"><FontAwesomeIcon onClick={handleCloseMeaning} icon={faXmark} style={{cursor: 'pointer'}} /></div>
				</div> 
			: null}
		</div>
	}
}
