import Axios from "axios"

Axios.defaults.headers = {
    "X-Requested-With": "XMLHttpRequest" // Для того, чтобы респонс с ошибкой был plain/text
}

Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
Axios.defaults.xsrfCookieName = "csrftoken" //"XCSRF-TOKEN"

export default class Http {
    constructor(baseUrl, context) {
        this.context = context
        this.baseUrl = baseUrl
            //console.log(`http init, baseUrl: ${baseUrl}, context: ${context}`)
    }

    get(url, params) {
        return Axios.get(`${this.baseUrl}${url}`, { params })
            .then(response => {
                return response.data
            })
            .catch(e => {
                this.handleError(e)
                return null
            })
    }

    post(url, data) {
        return Axios.post(`${this.baseUrl}${url}`, data)
            .then(response => {
                //console.log("http_get response", response)
                return response.data
            })
            .catch(e => {
                this.handleError(e)
                return null
            })
    }

    patch(url, data) {
        return Axios.patch(`${this.baseUrl}${url}`, data)
            .then(response => {
                //console.log("http_get response", response)
                return response.data
            })
            .catch(e => {
                this.handleError(e)
                return null
            })
    }

    delete(url, data) {
        return Axios.delete(`${this.baseUrl}${url}`, data)
            .then(response => {
                //console.log("http_get response", response)
                return response.data
            })
            .catch(e => {
                this.handleError(e)
                return null
            })
    }

    handleError(e) {
        if (e.response) {
            console.error(`SERVER ERROR: ${e.response.statusText} (${e.response.status})`)
            console.error('RESPONSE DATA:', e.response.data)
        } else {
            console.error(e)
        }

        // const { status, statusText } = error.request

        // const result = {
        // 	url: error.request.responseURL,
        // 	status,
        // 	statusText,
        // 	message,
        // 	serverData: error.response ? error.response.data : null
        // }
        // console.error("Server error")
        // console.error(error)
        // if (error.response) console.error("Server data", error.response.data)
        // console.log("BoardAPI request error", result.error)

        if (this.context)
            this.context.dispatch({ type: "addError", payload: e })
    }
}