import * as React from "react"

const AppContext = React.createContext()

const reducer = (state, action) => {
  //console.log('reducer', { state, action })
  switch (action.type) {
    case "addError":
      return { ...state, errors: [...state.errors, action.payload] }
    case "clearErrors":
      return { ...state, errors: [] }
    default:
      return { ...state }
  }
}

const initialState = {
  api: null,
}

function AppContextProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  )
}

let AppContextConsumer = AppContext.Consumer

export { AppContext, AppContextProvider, AppContextConsumer }